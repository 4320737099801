export default class ClickTargets {
  constructor() {
    this.targets = $('[data-ct]')
  }

  addEvent(e) {
    let item = $(e.currentTarget)

    if (typeof gtag !== 'undefined') {
      gtag('event', item.data('ct'), {
        event_category: item.data('ct-category'),
        event_label: item.data('ct-label'),
      })
    }

    if (typeof fbq !== 'undefined') {
      fbq('trackCustom', item.data('ct'), {
        event_category: item.data('ct-category'),
        event_label: item.data('ct-label'),
      })
    }
  }

  init() {
    if (this.targets.length) {
      this.targets.on('click', e => this.addEvent(e))
    }
  }
}
