/**
 * Flickity carousel for cars
 */
const Flickity = require('flickity')

class Cars {
  constructor() {
    this.instance = null
    this.carousel = document.querySelector('.cars__carousel')
    this.setCar = $('.cars__carousel button[data-car]')
    this.form = $('.form')
    this.selectLocation = $('.form select#l_location')
  }

  /**
   * Load Flickity instance
   */
  start() {
    this.instance = new Flickity(this.carousel, {
      autoPlay: false,
      pageDots: false,
      dragThreshold: 25,
      prevNextButtons: true,
      wrapAround: true,
      selectedAttraction: 0.05,
      friction: 0.3,
    })
  }

  /**
   * Reset car form on change
   */
  resetForm() {
    this.form.hide()
    this.form.find('select[name=car_id]').val('')
    this.form.find('select[name=location_id]').val('')
  }

  /**
   * Open car form
   */
  openForm(car) {
    this.resetForm()

    this.form.find('select[name=car_id]').val(car)
    this.form.show()

    $('html, body').animate({scrollTop: this.form.offset().top - 100}, 500)
  }

  /**
   * Load locations based on car type
   */
  loadLocations(car, loc = null) {
    this.form.find('select[name=car_id]').val(car)
    let _ = this

    let apiUrl = `/load-locations/${car}/${loc}`

    $.ajax({
      type: 'GET',
      url: apiUrl,
    }).done(function (data) {
      _.selectLocation.html(data)
      setTimeout(() => _.selectLocation.trigger('change'), 200)
    })
  }

  /**
   * Register event listeners
   */
  registerEvents() {
    this.setCar.on('click', e => this.openForm($(e.currentTarget).data('car')))

    this.instance.on('change', () => {
      let car = $(`.cars__carousel__item:nth(${this.instance.selectedIndex})`).find('.btn--primary').data('car')
      this.resetForm()
      this.form.find('select[name=car_id]').val(car)
    })
  }

  /**
   * Initialize class
   */
  init() {
    if (this.carousel) {
      this.start()

      if (this.instance) {
        this.registerEvents()
      }
    }
  }
}

export default Cars
