class Form {
  constructor() {
    this.fields = $('.form input[data-should-validate]')
    this.accept = $('input#l_accept')
    this.mediumPhone = $('input#l_medium_phone')
    this.mediumEmail = $('input#l_medium_email')
    this.submitBtn = $('.form__submit button[type=submit]')
  }

  checkValidity() {
    let isValid = true

    this.fields.each(function () {
      if ($(this).val() === '') {
        isValid = false
      }

      if ($(this).attr('id') === 'l_email' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($(this).val())) {
        isValid = false
      }
    })

    if (!this.mediumEmail.prop('checked') && !this.mediumPhone.prop('checked')) {
      isValid = false
    }

    if (!this.accept.prop('checked')) {
      isValid = false
    }

    this.submitBtn.attr('disabled', isValid ? false : 'disabled')
  }

  registerEvents() {
    this.fields.on('keyup', () => this.checkValidity())
    this.mediumEmail.on('change', () => this.checkValidity())
    this.mediumPhone.on('change', () => this.checkValidity())
    this.accept.on('change', () => this.checkValidity())
  }

  init() {
    this.registerEvents()
  }
}

export default Form
