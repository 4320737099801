window.$ = window.jQuery = require('jquery')

import Cars from './Cars.js'
import Form from './Form.js'
import Locations from './Locations.js'
import ClickTargets from './ClickTargets.js'

$(function () {
  // Load cars carousel
  let cars = new Cars()
  cars.init()

  // Handle form stuff
  let form = new Form()
  form.init()

  // Load locations carousel
  let locations = new Locations()
  locations.init()

  // Handle click events
  let ct = new ClickTargets()
  ct.init()

  // Kickstart frontend
  setTimeout(() => {
    $('html').removeClass('no-js no-transitions')

    $(document).on('click', '[data-scrollto]', function (e) {
      $('html, body').animate({scrollTop: $(`#${$(e.currentTarget).data('scrollto')}`).offset().top - 100}, 250)
    })

    let formMessage = $('.form__message:first')
    if (formMessage.length) {
      $('html, body').animate({scrollTop: formMessage.offset().top - 100}, 100)
    }
  }, 250)
})

/**
 * Scroll actions
 */
const headerElem = document.querySelector('.header')
const pxHideBranding = 1
let lastScrollPos = 0

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('scroll', () => {
    let os = window.pageYOffset || document.documentElement.scrollTop

    if (os < pxHideBranding) {
      headerElem.classList.remove('is-branded')
      headerElem.classList.remove('is-sticky')
    } else {
      if (os > lastScrollPos) {
        headerElem.classList.remove('is-branded')

        if (os > 0) {
          headerElem.classList.add('is-sticky')
        }
      } else {
        if (os > pxHideBranding) {
          headerElem.classList.add('is-branded')
        }
      }

      lastScrollPos = os <= 0 ? 0 : os
    }
  })
})

/**
 * Navigation toggler
 */
const navElem = document.querySelector('.navigation')
const navToggler = document.querySelector('.header__toggler')
const navClose = document.querySelector('.navigation__close')

if (navToggler && navElem) {
  document.addEventListener('DOMContentLoaded', () => {
    navToggler.addEventListener('click', () => {
      if (navElem.classList.contains('is-open')) {
        navElem.classList.remove('is-open')
      } else {
        navElem.classList.add('is-open')
      }
    })

    navClose.addEventListener('click', () => {
      navElem.classList.remove('is-open')
    })
  })
}
