/**
 * Flickity carousel for locations
 */
class Locations {
  constructor() {
    this.carousel = document.querySelector('.locations__carousel')
  }

  /**
   * Load Flickity instance
   */
  start() {
    const Flickity = require('flickity')

    const chooseLocation = new Flickity(this.carousel, {
      autoPlay: false,
      cellAlign: 'center',
      contain: true,
      initialIndex: '.is-initial-index',
      pageDots: true,
      prevNextButtons: false,
      wrapAround: false,
      groupCells: true,
      selectedAttraction: 0.05,
      friction: 0.3,
    })
  }

  /**
   * Initialize class
   */
  init() {
    if (this.carousel) {
      this.start()
    }
  }
}

export default Locations
